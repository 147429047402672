.container {
  margin:2em;
}

@import "~bootstrap/scss/bootstrap";

h1 {
  font-size:1.8em;
}

h2 {
  font-size:1.6em;
  border-bottom: 1px solid #333;
}

h3 {
  font-size:1.4em;
}

// Loader
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 3px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #CCC;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

#h1rcr {
  padding-left: 2em;
}

#h1ppn {
  padding-left: 4em;
}

#h1rcr::before, #h1ppn::before {
  content: "→ ";
}

#unloca {
  display: none;
}

#btn_unimarc {
  margin-bottom: 1em;
}

footer {
  border-top:  1px solid #888;
}